import React from 'react';
import { Link } from 'react-router-dom';
import { Product, ProductCardPhoto, ProductText, ProductFooter, ProductAddToCart } from '../styles.js';

const ProductCard = (props) => {
  const product = props.product;
  return (
    <Product>
      <Link to={`/products/${product.id}`} className='photo-link'>
        <ProductCardPhoto src={product.feature_photo_url} alt={`${product.name}`} />
      </Link>
      <ProductFooter>
        <ProductText>
          <Link to={`/products/${product.id}`}><h2>{product.name}</h2></Link>
          <p>${product.price}</p>
        </ProductText>
        <ProductAddToCart>
          <ion-icon name="add-outline" title="Add to Cart"></ion-icon>
          <p>Add to Cart</p>
        </ProductAddToCart>
      </ProductFooter>
    </Product>
  )
}

export default ProductCard;
