import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NavBar, Logo, Links, MenuLinks, ProfilePhoto } from "../styles.js";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../utilities/actions";
import axios from "axios";

const MainNav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toggle = (e) => {
    const links = document.querySelector(".nav-links");
    if (links.style.visibility === "visible") {
      links.style.visibility = "hidden";
      links.style.height = "0px";
      links.style.animation = "fade-out 1s";
    } else {
      links.style.visibility = "visible";
      links.style.height = "auto";
      links.style.animation = "fade-in 1s";
    }
  };

  const loggedIn = useSelector((state) => state.loggedIn);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (sessionStorage.token) {
      axios
        .get(
          `https://lowededgreens-api.herokuapp.com/api/users/${sessionStorage.id}`,
          {
            headers: { authorization: sessionStorage.token },
          }
        )
        .then((res) => {
          dispatch(setUser(res.data.user));
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <NavBar>
        <Logo>
          <img
            src="/images/LG-Mobile-Logo.png"
            alt="Loweded Greens Logo"
            onClick={() => navigate("/")}
          />
          <MenuLinks>
            {loggedIn ? (
              <Link to="/dashboard">
                <ProfilePhoto
                  src={user.profile_photo_url}
                  alt="Profile"
                  title="Account"
                />
              </Link>
            ) : (
              <Link to="/login">
                <ion-icon
                  name="person-circle-outline"
                  title="Log In"
                ></ion-icon>
              </Link>
            )}
            <ion-icon name="menu-outline" onClick={toggle}></ion-icon>
          </MenuLinks>
        </Logo>
      </NavBar>
      <Links className="nav-links">
        <Link to="/" onClick={toggle}>
          Home
        </Link>
        <Link to="/products" onClick={toggle}>
          Products
        </Link>
        <Link to="/contact" onClick={toggle}>
          Contact
        </Link>
        <Link to="/why-loweded-greens" onClick={toggle}>
          Why Loweded Greens?
        </Link>
      </Links>
    </>
  );
};

export default MainNav;
