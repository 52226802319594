import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  ProductDetailContainer,
  ProductPhoto,
  ProductDetailInfo,
  ProductBenefits,
  Benefit,
} from "../styles.js";

const ProductDetails = () => {
  const params = useParams();
  const id = params.id;
  const [product, setProduct] = useState();

  useEffect(() => {
    axios
      .get(`https://lowededgreens-api.herokuapp.com/api/products/${id}`)
      .then((response) => {
        setProduct(response.data.product);
      });
  }, [id]);

  if (!product) {
    return (
      <ProductDetailContainer>
        <h1>Loading product</h1>
        <div className="lds-dual-ring"></div>
      </ProductDetailContainer>
    );
  }

  return (
    <ProductDetailContainer>
      <ProductPhoto src={product.feature_photo_url} alt={`${product.name}`} />
      <ProductDetailInfo>
        <h2>{product.name}</h2>
        <p>{product.description}</p>
        <p>${product.price}</p>
        <Link to="/cart">Add to Cart</Link>
        <ProductBenefits>
          <Benefit>
            <ion-icon name="rocket-outline"></ion-icon>
            <p>Immunity Boosting</p>
          </Benefit>
          <Benefit>
            <ion-icon name="nutrition-outline"></ion-icon>
            <p>Digestive Support</p>
          </Benefit>
          <Benefit>
            <ion-icon name="heart-outline"></ion-icon>
            <p>Cleansing</p>
          </Benefit>
        </ProductBenefits>
      </ProductDetailInfo>
    </ProductDetailContainer>
  );
};

export default ProductDetails;
