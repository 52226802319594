import { SET_LOGGEDIN, SET_USER } from "../actions";

const initialState = {
  loggedIn: sessionStorage.token ? true : false,
  user: {},
};

export const rootReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOGGEDIN:
      return {
        ...state,
        loggedIn: payload,
      };
    case SET_USER:
      return {
        ...state,
        user: payload,
      };
    default:
      return {
        ...state,
        //do nothing
      };
  }
};
