import React from 'react';
import { Link } from 'react-router-dom';
import { Footer, Copyright, FooterLinks } from '../styles.js';

const MainFooter = () => {
  return (
    <Footer>
      <FooterLinks>
        <Link to="/">Home</Link>
        <Link to="/products">Products</Link>
        <Link to="/contact">Contact</Link>
        <Link to="/why-loweded-greens">Why Loweded Greens?</Link>
      </FooterLinks>
      <Copyright>&copy;2020 Loweded Greens</Copyright>
    </Footer>
  )
}

export default MainFooter;
