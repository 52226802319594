import React, { useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { LoginForm } from "../styles.js";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedIn, setUser } from "../utilities/actions";
import * as Yup from "yup";

const SignupSchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().min(6, "Too Short!").required("Required"),
});

const Login = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedIn = useSelector((state) => state.loggedIn);

  useEffect(() => {
    if (sessionStorage.token) {
      axios
        .get(
          `https://lowededgreens-api.herokuapp.com/api/users/${sessionStorage.id}`,
          {
            headers: { authorization: sessionStorage.token },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(setLoggedIn(true));
          } else {
            dispatch(setLoggedIn(false));
          }
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loggedIn) {
    navigate("/dashboard");
  }

  return (
    <LoginForm>
      <h1>CREATE AN ACCOUNT</h1>
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          password: "",
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, { setSubmitting }) => {
          axios
            .post(
              "https://lowededgreens-api.herokuapp.com/api/users/register",
              values
            )
            .then((res) => {
              dispatch(setLoggedIn(true));
              dispatch(setUser(res.data.user));
              sessionStorage.setItem("id", res.data.user.id);
              sessionStorage.setItem("token", res.data.token);
              navigate("/");
            })
            .catch((err) => {
              console.error(err);
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <label>
              First Name
              <Field type="text" name="first_name" />
              <ErrorMessage name="first_name" component="div" />
            </label>
            <label>
              Last Name
              <Field type="text" name="last_name" />
              <ErrorMessage name="last_name" component="div" />
            </label>
            <label>
              Email*
              <Field type="text" name="email" />
              <ErrorMessage name="email" component="div" />
            </label>
            <label>
              Password*
              <Field type="password" name="password" />
              <ion-icon
                name="eye-outline"
                class="maskPassword"
                style={{
                  position: "relative",
                  "font-size": "32px",
                  "margin-left": "300px",
                  "margin-top": "-50px",
                  "z-index": 2,
                }}
                onClick={() => {
                  let mask = document.querySelector(".maskPassword");
                  let passwordField = document.querySelector(
                    "input[name='password']"
                  );
                  mask.name === "eye-outline"
                    ? (mask.name = "eye-off-outline")
                    : (mask.name = "eye-outline");
                  passwordField.type === "password"
                    ? (passwordField.type = "text")
                    : (passwordField.type = "password");
                }}
              ></ion-icon>
              <ErrorMessage name="password" component="div" />
            </label>
            <button type="submit" disabled={isSubmitting}>
              Create Account
            </button>
          </Form>
        )}
      </Formik>
      <p>
        Already have an account? Please,{" "}
        <Link to="/login">
          <u>log in instead</u>
        </Link>
        .
      </p>
    </LoginForm>
  );
};

export default Login;
