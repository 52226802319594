import React from 'react';
import { Container, Hero, Header, CenteredParagraph } from '../styles.js';

const Home = () => {
  return (
    <>
      <Hero src='/images/loweded_greens_hero.jpg' alt='Featured Juice' />
      <Container>
        <Header>The Loweded Greens Benefit</Header>
        <CenteredParagraph>Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crow's nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters. Deadlights jack lad schooner scallywag dance the hempen jig carouser broadside cable strike colors. </CenteredParagraph>
      </Container>
    </>
  )
}

export default Home;
