import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";

import MainNav from "./components/MainNav.js";
import Home from "./components/Home.js";
import ProductList from "./components/ProductList.js";
import ProductDetails from "./components/ProductDetails.js";
import MainFooter from "./components/Footer.js";
import Login from "./components/Login.js";
import Dashboard from "./components/Dashboard.js";
import Register from "./components/Register.js";
import Order from "./components/Order.js";

function App() {
  return (
    <div className="App">
      <div className="content">
        <MainNav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/products/:id" element={<ProductDetails />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/order" element={<Order />} />
        </Routes>
      </div>
      <MainFooter />
    </div>
  );
}

export default App;
