import styled from "styled-components";

export const NavBar = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
  border-bottom: 1px solid #e8e8e8;
`;
export const Logo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  img {
    width: 75px;
    &:hover {
      cursor: pointer;
    }
  }
`;
export const Links = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  visibility: hidden;
  height: 0px;
  animation: fade-out 1s;
  a {
    text-decoration: none;
    padding: 15px 30px;
    color: inherit;
    border-bottom: 1px solid #e8e8e8;
  }
`;
export const MenuLinks = styled.div`
  display: flex;
  ion-icon {
    margin: 0 5px;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
`;
export const Container = styled.div`
  max-width: 100%;
  padding: 40px 40px;
  flex: 1 0 auto;
`;
export const Hero = styled.img`
  width: 100%;
  height: auto;
`;
export const Header = styled.h1`
  text-align: center;
`;
export const CenteredParagraph = styled.p`
  text-align: center;
`;
export const Footer = styled.footer`
  background-color: #e8e8e8;
  flex-shrink: 0;
`;
export const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  a {
    text-decoration: none;
    padding: 15px 30px;
    color: inherit;
    border-bottom: 1px solid #e8e8e8;
    &:hover {
      text-decoration: underline;
    }
  }
`;
export const Copyright = styled.div`
  background-color: #42af74;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  border-top: 1px solid #42af74;
`;
export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
`;
export const Product = styled.div`
  width: 80%;
  border-radius: 7px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .photo-link {
    line-height: 0;
  }
`;
export const ProductCardPhoto = styled.img`
  height: 400px;
  width: 100%;
  border-radius: 7px 7px 0 0;
`;
export const ProductPhoto = styled.img`
  width: 100%;
  height: auto;
`;
export const ProductText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  a {
    text-decoration: none;
    color: inherit;
  }
  h2 {
    margin: 2px 0;
    padding-left: 20px;
    font-size: 22px;
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    margin: 2px 0;
    padding-left: 20px;
  }
`;
export const ProductFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #77e2a3;
`;
export const ProductAddToCart = styled.div`
  border-left: 1px solid #42af74;
  height: 100%;
  padding: 20px;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
  p {
    margin: 0;
    font-size: 10px;
  }
`;
export const ProductDetailContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ProductDetailInfo = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  h2,
  p {
    margin: 5px 0;
  }
  a {
    text-decoration: none;
    color: #fff;
    background-color: #42af74;
    padding: 15px 80px;
    margin: 20px 0;
  }
`;
export const ProductBenefits = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
  align-items: center;
  border-top: 1px solid #77e2a3;
  border-bottom: 1px solid #77e2a3;
`;
export const Benefit = styled.div`
  width: 30%;
  padding: 0 1.15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ion-icon {
    color: #77e2a3;
  }
  p {
    text-align: center;
  }
`;
export const FacebookLoginButton = styled.button`
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354c8c;
  cursor: pointer;
  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 100%;
    border-right: #364e92 1px solid;
    background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png")
      6px 6px no-repeat;
  }
  &:focus {
    outline: none;
  }
  &:active {
    box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
  }
  &:hover,
  &:focus {
    background-color: #5b7bd5;
    background-image: linear-gradient(#5b7bd5, #4864b1);
  }
`;
export const GoogleLoginButton = styled.button`
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
  background: #dd4b39;
  cursor: pointer;
  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 100%;
    border-right: #bb3f30 1px solid;
    background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png")
      6px 6px no-repeat;
  }
  &:focus {
    outline: none;
  }
  &:active {
    box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
  }
  &:hover,
  &:focus {
    background: #e74b37;
  }
`;
export const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px auto;
  text-align: center;
  h1,
  p {
    margin: 0;
  }
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 40px auto 0;
    text-align: left;
  }
  label {
    display: flex;
    flex-direction: column;
    margin: 0 15%;
    input {
      margin: 10px auto;
      width: 100%;
      height: 45px;
      border: 1px solid #cecece;
      padding-left: 15px;
    }
    div {
      color: red;
    }
    .error {
      border: 1px solid red;
    }
  }
  button {
    color: #fff;
    background-color: #42af74;
    border: none;
    padding: 15px 80px;
    margin: 20px 15%;
  }
`;
export const ProfilePhoto = styled.img`
  border-radius: 50%;
  width: 32px !important;
`;
