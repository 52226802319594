import React, { useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { LoginForm } from "../styles.js";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedIn, setUser } from "../utilities/actions";

const Login = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedIn = useSelector((state) => state.loggedIn);

  useEffect(() => {
    if (sessionStorage.token) {
      axios
        .get(
          `https://lowededgreens-api.herokuapp.com/api/users/${sessionStorage.id}`,
          {
            headers: { authorization: sessionStorage.token },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(setLoggedIn(true));
          } else {
            dispatch(setLoggedIn(false));
          }
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loggedIn) {
    navigate("/dashboard");
  }

  return (
    <LoginForm>
      <h1>LOGIN</h1>
      <Formik
        initialValues={{ email: "", password: "" }}
        validate={(values) => {
          const emailInput = document.querySelector('input[name="email"]');
          const passwordInput = document.querySelector(
            'input[name="password"]'
          );
          const errors = {};
          if (!values.email) {
            errors.email = "Email is required";
            emailInput.classList.add("error");
          } else if (!values.password) {
            errors.password = "Password is required";
            passwordInput.classList.add("error");
          } else if (values.email && values.password) {
            emailInput.classList.remove("error");
            passwordInput.classList.remove("error");
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          axios
            .post(
              "https://lowededgreens-api.herokuapp.com/api/users/login",
              values
            )
            .then((res) => {
              dispatch(setLoggedIn(true));
              dispatch(setUser(res.data.user));
              sessionStorage.setItem("id", res.data.user.id);
              sessionStorage.setItem("token", res.data.token);
              navigate("/");
            })
            .catch((err) => {
              console.error(err);
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <label>
              Email
              <Field type="text" name="email" />
              <ErrorMessage name="email" component="div" />
            </label>
            <label>
              Password
              <Field type="password" name="password" />
              <ErrorMessage name="password" component="div" />
            </label>
            <button type="submit" disabled={isSubmitting}>
              Login
            </button>
          </Form>
        )}
      </Formik>
      <p>
        Don't have an account? Please,{" "}
        <Link to="/register">
          <u>sign up here</u>
        </Link>
        .
      </p>
    </LoginForm>
  );
};

export default Login;
