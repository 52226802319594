export const SET_LOGGEDIN = "SET_LOGGEDIN";
export const SET_USER = "SET_USER";

export const setLoggedIn = (status) => ({
  type: SET_LOGGEDIN,
  payload: status,
});

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});
