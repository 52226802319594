import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setLoggedIn } from "../utilities/actions";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.loggedIn);
  const user = useSelector((state) => state.user);

  const logOut = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("id");
    dispatch(setLoggedIn(false));
    navigate("/");
  };

  if (!loggedIn) {
    navigate("/login");
  }

  if (user && user.is_admin) {
    return (
      <>
        <h1>Welcome admin</h1>
        <button onClick={logOut}>Log out</button>
      </>
    );
  } else if (user && !user.is_admin) {
    return (
      <>
        <h1>Welcome regular</h1>
        <button onClick={logOut}>Log out</button>
      </>
    );
  }

  return (
    <>
      <h2>Loading...</h2>
    </>
  );
};

export default Dashboard;
