import React, { useEffect, useState } from "react";
import axios from "axios";
import ProductCard from "./ProductCard.js";
import { CardContainer } from "../styles.js";

const ProductList = () => {
  const [products, setProducts] = useState();

  useEffect(() => {
    axios
      .get("https://lowededgreens-api.herokuapp.com/api/products")
      .then((response) => {
        setProducts(response.data.products);
      });
  }, []);

  if (!products) {
    return (
      <CardContainer>
        <h1>Loading products</h1>
        <div className="lds-dual-ring"></div>
      </CardContainer>
    );
  }
  return (
    <CardContainer>
      {products.map((product, index) => {
        return <ProductCard product={product} key={index} />;
      })}
    </CardContainer>
  );
};

export default ProductList;
